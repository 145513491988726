import React from 'react';
import PropTypes from 'prop-types';
export const ExternalLinks = ({ links }) => {
  if (!links) {
    return null;
  }
  return (
    <ul className="pf-external-links">
      {Object.keys(links).map((linkKey) => {
        const link = links[linkKey];
        return (
          <li key={linkKey}>
            <a href={link.path} target='_blank' rel="noreferer noreferrer">{link.label}</a>
          </li>
        );
      })}
    </ul>
  );
};

export const linkType = PropTypes.shape({
  label: PropTypes.string,
  path: PropTypes.string
});

ExternalLinks.PropTypes = {
  links: PropTypes.shape({
    termsOfUse: linkType,
    privacy: linkType,
    accessibility: linkType
  })
};


require('/var/lib/jenkins/workspace/pf-landing-pages_Release_0/src/shared_modules/landing/components/external-links/styles.scss');