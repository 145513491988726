import React from 'react';
import PropTypes from 'prop-types';

const PromoSection = ({ baseClass, className, data }) => {

  const { title, desc, sections } = data,
    sectionKeys = (data && sections && Object.keys(sections)) || [],
    cls = (className) ? `${className}` : '',
    wrapClass = (cls) ? `${cls} ${baseClass}` : baseClass,
    clsHeading = `${baseClass}__heading`,
    clsTitle = `${baseClass}__title`,
    clsDesc = `${baseClass}__desc`;

  // render empty component, no data
  if (sectionKeys.length === 0) {
    return null;
  }

  return (
    <section className={wrapClass}>
      <div className='page-inner'>
        <div className={clsHeading}>
          <h2 className={clsTitle}>{title}</h2>
          <div className={clsDesc}>{desc}</div>
        </div>
        { sectionKeys.map((key, idx) => {
            const { title, desc } = sections[key],
              colClass = 'sub-caption sub-caption-' + (idx + 1),
              imgClass = key + ' promo-image';
            return (
              <div key={key} className={colClass}>
                <div className={imgClass}></div>
                <div className='sub-caption-info'>
                  <h3
                    className='sub-caption-info__title'
                  >{title}</h3>
                  <div
                    className='sub-caption-info__desc'
                  >{desc}</div>
                </div>
                <hr className='divider' />
              </div>
            );
          })
        }
      </div>
    </section>
  );
};

PromoSection.defaultProps = {
  baseClass: 'promo',
  data: { sec: { title: '', desc: '', sections: {} } },
  className: ''
};

PromoSection.propTypes = {
  className: PropTypes.string,
  baseClass: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
};

export default PromoSection;

