import React from 'react';
import PropTypes from 'prop-types';

const HeroSection = ({ className, data, baseImgClass }) => {

  // render empty component, no data
  if (!data || data.length === 0) {
    return null;
  }

  const baseClass = 'hero',
    heroImgClass = `${baseImgClass} ${baseClass}-image`,
    wrapClass = (className)
      ? `${className} ${baseClass}` : baseClass,
    colClass = `col col-${Math.floor(12 / data.length)}`;

  return (
    <section className={wrapClass}>
      <div className={heroImgClass} />
      <div className='page-inner'>
        { data.map((sec, idx) => {
          const iterClass = `column-${(idx + 1)}`,
            columnClass = `${colClass} ${iterClass}`;
          return (
            <div
              key={iterClass}
              className={columnClass}
            >{data[idx]}</div>
          );
        })}
      </div>
    </section>
  );
};

HeroSection.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  baseImgClass: PropTypes.string
};

export default HeroSection;

