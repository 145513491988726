import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Row from '@shared_modules/landing/components/grid/Row';
import Col from '@shared_modules/landing/components/grid/Col';

class Phone extends Component {

  constructor(props) {
    super(props);
    this.state = {
      msgData: {
        errors: { phone: {} },
        info: { phone: {} }
      }
    };
  }

  static defaultProps = {
    element: {
      name: 'phone',
      id: 'pf-landing-phone',
      label: 'Primary Phone',
      label2: 'Number',
      placeHolder: '',
      colLabelClassName: 'label',
      colFieldClassName: 'field',
      colValidationClassName: 'messaging'
    },
    colLayout: {
      1: { sm: 12, lg: 3 },
      2: { sm: 12, lg: 3 },
      3: { sm: 12, lg: 6 }
    }
  }

  handleValueChange = (valueObj, ev) => {
    const { fieldMetadata } = this.props;
    fieldMetadata.value = valueObj.value;
  }

  handleBlur = (ev) => {
    const { onEventCallback, fieldMetadata } = this.props;

    if (fieldMetadata.value.length !== 10) {
      fieldMetadata.status = 'invalid';
      fieldMetadata.isValid = false;
    } else {
      fieldMetadata.status = '';
      fieldMetadata.isValid = true;
    }

    if (onEventCallback && typeof onEventCallback === 'function') {
      onEventCallback(ev, fieldMetadata);
    }
  }

  handleFocus = (ev) => {
    const { fieldMetadata } = this.props;
    fieldMetadata.status = '';
  }

  render() {
    const { colLayout, element, fieldMetadata, pageType } = this.props;

    return (
      <Row className={element.id}>
        <Col
          className={element.colLabelClassName}
          width={colLayout[1]}
        >
          <label
            id={element.id + '-label'}
            className={pageType + '-label'}
            htmlFor={element.id}>
            {fieldMetadata.label}
          </label>
        </Col>
        <Col
          className={element.colFieldClassName}
          width={colLayout[2]}
        >
          <NumberFormat
            key={fieldMetadata.value}
            id={element.id}
            name={fieldMetadata.name}
            className={fieldMetadata.status}
            type='tel'
            format='(###) ###-####'
            mask='_'
            placeholder={element.placeHolder}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onValueChange={this.handleValueChange}
            defaultValue={fieldMetadata.value}
            autoComplete={fieldMetadata.autocomplete || null}
          />
        </Col>
      </Row>
    );
  }
}

Phone.propTypes = {
  colLayout: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  onEventCallback: PropTypes.func,
  fieldMetadata: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired
};

export default Phone;

