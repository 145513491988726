const titles = {
  'dental': [
    'Empower', 'your', 'patients', 'with',
    'more', 'affordable', 'payment', 'plans'
  ],
  'implants': [
    'Your', 'patients', 'can', 'finance',
    'a dental', 'implant', 'for', '$99/month*'
  ],
  'cosmetic': [
    'Increase', 'cosmetic', 'case',
    'acceptance', 'at', 'your', 'practice'
  ]
};

export default titles;

require('/var/lib/jenkins/workspace/pf-landing-pages_Release_0/src/pages/trypatientsolutions-plus/trypatientsolutions-plus.scss');