const formMeta = {
  title: 'Download a free overview of payment options.',
  fields: {
    'practice-name': {
      type: 'text',
      name: 'practice-name',
      label: 'Practice Name',
      maxLength: 45,
      isRequired: true,
      isValid: false,
      value: '',
      status: ''
    },
    'practice-zip-code': {
      type: 'text',
      name: 'practice-zip-code',
      label: 'Practice Zip Code',
      maxLength: 45,
      isRequired: true,
      isValid: false,
      value: '',
      status: ''
    },
    'provider-name': {
      type: 'text',
      name: 'provider-name',
      label: 'Name',
      maxLength: 45,
      isRequired: true,
      isValid: false,
      value: '',
      status: ''
    },
    'email': {
      type: 'text',
      name: 'email',
      label: 'Email',
      maxLength: 50,
      isRequired: true,
      isValid: false,
      value: '',
      status: ''
    },
    'how-hear': {
      type: 'select',
      name: 'how-hear',
      label: 'How did you hear about us?',
      isRequired: true,
      isValid: false,
      value: '',
      status: ''
    },
    /* // removed per Tommy, in favor of 'how-hear'
    'job-title': {
      type: 'text',
      label: 'Job Title',
      placeholder: 'ex. Office Manager',
      maxLength: 45,
      isRequired: true,
      isValid: false,
      value: '',
      status: ''
    },
    */
    'phone-number': {
      type: 'text',
      name: 'phone-number',
      label: 'Phone Number',
      placeholder: '(___)___-____',
      maxLength: 45,
      isRequired: true,
      isValid: false,
      value: '',
      status: ''
    }
  }
};

export default formMeta;

require('/var/lib/jenkins/workspace/pf-landing-pages_Release_0/src/pages/trypatientsolutions-plus/trypatientsolutions-plus.scss');