export const getFootnotesData = (rates) => {
  const footnoteData = {};
  const commonNotes = [
    `Applications are subject to credit approval. Loan amounts range from $1,000 to $50,000, and loan term lengths range from 24 months to 84 months. Some amounts and term lengths may be unavailable in certain states or through certain providers. APR ranges from ${rates.min}% to ${rates.max}% and is determined at the time of application. Lowest APR is available to borrowers with excellent credit. Advertised rates and fees are valid as of September 2021 and are subject to change without notice`,
    'Credit terms will depend upon the applicant\'s credit score and other key financing characteristics, including but not limited to the amount financed, loan term length, and the applicant\'s credit usage and history, as well as the applicant\'s selected service provider. Applications are subject to credit approval.',
    'Installment loans are issued by LendingClub Bank, N.A., Member FDIC, Equal Housing Lender ("LendingClub Bank"), a wholly-owned subsidiary of LendingClub Corporation, NMLS ID 167439. Revolving lines of credit are issued by Comenity Capital Bank, Member FDIC.',
    '"LendingClub" and the "LC" symbol, and "LendingClub Patient Solutions," are trademarks of LendingClub Bank.'
  ];
  footnoteData.implants = [
    '* $99 monthly payment assumes a 60-month term installment loan with an amount financed of $4,250 and an APR of 13.99%. Amount financed is based on an average single implant procedure cost of $4,250. Source: https://www.dentalimplantcostguide.com/resources/.',
    ...commonNotes
  ];
  footnoteData.dental = commonNotes;
  footnoteData.cosmetic = commonNotes;
  return footnoteData;
};

require('/var/lib/jenkins/workspace/pf-landing-pages_Release_0/src/pages/trypatientsolutions-plus/trypatientsolutions-plus.scss');