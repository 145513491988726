import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Row from '@shared_modules/landing/components/grid/Row';
import Col from '@shared_modules/landing/components/grid/Col';

class SelectInput extends Component {

  static defaultProps = {
    element: {
      id: 'how-hear',
      name: 'name',
      colLabelClassName: 'label',
      colFieldClassName: 'field',
      colValidationClassName: 'messaging'
    },
    colLayout: {
      1: { sm: 12, lg: 3 },
      2: { sm: 12, lg: 3 },
      3: { sm: 12, lg: 3 },
      4: { sm: 12, lg: 3 }
    }
  }

  handleChange = (ev) => {
    const { value } = ev.target,
      { fieldMetadata } = this.props;

    fieldMetadata.value = value;
    fieldMetadata.isValid = value ? true : false;
    fieldMetadata.status = value ? '' : 'invalid';
  }

  handleFocus = (ev) => {
    const { fieldMetadata } = this.props;
    fieldMetadata.status = '';
  }

  handleBlur = (ev) => {
    const { value } = ev.target,
      { fieldMetadata, onEventCallback } = this.props;

    fieldMetadata.value = value;
    fieldMetadata.isValid = value ? true : false;
    fieldMetadata.status = value ? '' : 'invalid';

    if (onEventCallback && typeof onEventCallback === 'function') {
      onEventCallback(ev, fieldMetadata);
    }
  }

  render() {
    const { colLayout, element, fieldMetadata, pageType } = this.props,
      prefix = 'pf-landing-';

    return (
      <Row className={prefix + element.name}>
        <Col
          className={element.colLabelClassName}
          width={colLayout[1]}
        >
          <label
            id={prefix + element.id + '-label'}
            className={pageType + '-label'}
            htmlFor={prefix + fieldMetadata.name}
          >{fieldMetadata.label}</label>
        </Col>
        <Col
          className={element.colFieldClassName}
          width={colLayout[2]}
        >
          <select
            key={fieldMetadata.value}
            id={prefix + fieldMetadata.name}
            className={fieldMetadata.status}
            defaultValue={fieldMetadata.value}
            name={fieldMetadata.name}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          >
            <option value=''>Select</option>
            <option value='Consultant or Lab'>Consultant, lab, vendor or lecture</option>
            <option value='Magazine Ad'>Print advertisement</option>
            <option value='Email or Web Ad'>Email or online advertisement</option>
            <option value='Direct mail'>Direct mail</option>
            <option value='Referral'>Word of mouth or referral</option>
            <option value='Tradeshow'>Meeting or tradeshow</option>
            <option value='Web search'>Web search</option>
            <option value='LendingClub Rep'>Lending Club Patient Solutions representative</option>
            <option value='Other'>Other or I don&#39;t know</option>
          </select>
        </Col>
      </Row>
    );
  }
}

SelectInput.propTypes = {
  colLayout: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  onEventCallback: PropTypes.func,
  fieldMetadata: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired
};

export default SelectInput;

