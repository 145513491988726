import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

class NavMenu extends Component {

  constructor(props) {
    super(props);

    this.state = {
      displayActions: false,
      showMenu: false
    };
  }

  static defaultProps = {
    navItems: [],
    navActions: [],
    navBack: {
      text: 'Back'
    },
    baseClass: 'nav-menu',
    showClass: 'show',
    closeClass: 'close'
  }

  toggleMenu = () => {
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu});
  }

  renderNavBack = () => {
    const { text } = this.props.navBack;

    return (
      <div
        className='nav__back'
        onClick={this.toggleMenu}
      >
        <span className='arrow_back'></span>
        <span>{text}</span>
      </div>
    );
  }

  render() {
    const
      { showMenu } = this.state,
      { navItems, navActions, baseClass,
        className, showClass, closeClass,
        navBack } = this.props,
      wrapClass = (className)
        ? `${className} ${baseClass}` : baseClass,
      navWrapperClass = classnames({
        [wrapClass]: true,
        [showClass]: showMenu,
        [closeClass]: !showMenu
      }),
      navClasses = classnames({
        'hamburger-menu': true,
        'hamburger-menu--close': showMenu
      });

    return (
      <nav className={navWrapperClass}>
        <div
          id='pf-nav-menu-mobile-nav'
          className={navClasses}
          onClick={this.toggleMenu}
        />
        <div className='nav-menu-items'>
          { (navItems.length > 0) &&
            <ul className='nav-toolbar items'>
              <li className='nav-link back'>
                <a
                  href='#'
                  onClick={this.toggleMenu}
                >
                  <span className='arrow-back' />
                  <span>{navBack.text}</span>
                </a>
              </li>
              { navItems.map((item, idx) => (
                  <li key={'nav-item-' + idx} className='nav-link'>
                    <a href={item.href}>{item.text}</a>
                  </li>
                ))
              }
            </ul>
          }
          { (navActions.length > 0) &&
            <ul className='nav-toolbar actions'>
              { navActions.map((item, idx) => (
                  <li key={'nav-item-' + idx} className='nav-action'>
                    <a className='nav-button' href={item.href}>{item.text}</a>
                  </li>
                ))
              }
            </ul>
          }
        </div>
      </nav>
    );
  }
}

NavMenu.propTypes = {
  baseClass: PropTypes.string.isRequired,
  className: PropTypes.string,
  navActions: PropTypes.array,
  navItems: PropTypes.array.isRequired
};

export default NavMenu;

