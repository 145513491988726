export default (minRate) => {
  const checkDescription = `Three payment plans available, with APRs as low as ${minRate}% and terms out to 84 months.`;

  return {
    dental: {
      topPromo: {
        title: 'Offer the best in dental financing to your patients',
        desc: 'Quality financing that matches the quality of your care',
        sections: {
          'person-group': {
            title: 'Flexibility for your patients',
            desc: 'Patients can see all payment options with no impact to their credit until they select a plan and move forward.'
          },
          check: {
            title: 'More affordable payments',
            desc: checkDescription
          },
          'medical-file': {
            title: 'Increase in case acceptance',
            desc: 'Monthly payments that fit in your patient\'s budget help them get the smile they deserve.'
          }
        }
      },
      btmPromo: {
        title: 'Three simple steps',
        desc: 'Register today so you can focus on what matters most - your dental care.',
        sections: {
          stopwatch: {
            title: '1. Register online',
            desc: 'Registration is free and easy.'
          },
          nurse: {
            title: '2. Receive training',
            desc: 'Our dental financing experts will get your staff up and running.'
          },
          'computer-c': {
            title: '3. Start accepting applications',
            desc: 'We forward payment directly to you within 3 business days.'
          }
        }
      }
    },
    implants: {
      topPromo: {
        title: 'Offer the best in large case dental financing',
        desc: 'Quality financing that matches the quality of your care',
        sections: {
          'person-group': {
            title: 'Flexibility for your patients',
            desc: 'Patients can see all payment options with no impact to their credit until they select a plan and move forward.'
          },
          check: {
            title: 'More affordable payments',
            desc: checkDescription
          },
          'medical-file': {
            title: 'Increase in case acceptance',
            desc: 'Monthly payments that fit in your patient\'s budget help them get the smile they deserve.'
          }
        }
      },
      btmPromo: {
        title: 'Three simple steps',
        desc: 'Register today so you can focus on what matters most - your dental care.',
        sections: {
          stopwatch: {
            title: '1. Register online',
            desc: 'Registration is free and easy.'
          },
          nurse: {
            title: '2. Receive training',
            desc: 'Our dental financing experts will get your staff up and running.'
          },
          'computer-c': {
            title: '3. Start accepting applications',
            desc: 'We forward payment directly to you within 3 business days.'
          }
        }
      }
    },
    cosmetic: {
      topPromo: {
        title: 'Offer the best in large case dental financing',
        desc: 'Quality financing that matches the quality of your care',
        sections: {
          'person-group': {
            title: 'Flexibility for your patients',
            desc: 'Patients can see all payment options with no impact to their credit until they select a plan and move forward.'
          },
          check: {
            title: 'More affordable payments',
            desc: checkDescription
          },
          'medical-file': {
            title: 'Increase in case acceptance',
            desc: 'Monthly payments that fit in your patient\'s budget help them get the smile they deserve.'
          }
        }
      },
      btmPromo: {
        title: 'Three simple steps',
        desc: 'Register today so you can focus on what matters most - your dental care.',
        sections: {
          stopwatch: {
            title: '1. Register online',
            desc: 'Registration is free and easy.'
          },
          nurse: {
            title: '2. Receive training',
            desc: 'Our dental financing experts will get your staff up and running.'
          },
          'computer-c': {
            title: '3. Start accepting applications',
            desc: 'We forward payment directly to you within 3 business days.'
          }
        }
      }
    }
  };
};

require('/var/lib/jenkins/workspace/pf-landing-pages_Release_0/src/pages/trypatientsolutions-plus/trypatientsolutions-plus.scss');