import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Col extends Component {

  static defaultProps = {
    className: null,
    baseClass: 'col',
    width: {
      sm: 0, md: 0, lg: 0,
      xl: 0, xxl: 0
    }
  }

  render() {
    const { width, className, baseClass, children } = this.props,
      {sm, md, lg, xl, xxl} = width,
      classes = classnames({
        [baseClass]: true,
        [`sm-${sm}`]: sm,
        [`md-${md}`]: md,
        [`lg-${lg}`]: lg,
        [`xlg-${xl}`]: xl,
        [`xxlg-${xxl}`]: xxl,
        [`${className}`]: className
      });

    return (
      <div className={classes}>{children}</div>
    );
  }
}

Col.propTypes = {
  width: PropTypes.object.isRequired,
  className: PropTypes.string,
  baseClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Col;

