import React from 'react';
import PropTypes from 'prop-types';
import { ExternalLinks, linkType } from './external-links';

const LandingFooter = ({
  externalLinks,
  footnotes,
  copyRightYear,
  displayLegalUpdates
}) => {
  const year = (copyRightYear)
    ? copyRightYear : (new Date().getFullYear());

    const footerText = displayLegalUpdates
      ? `&copy; ${year} LendingClub Bank. All rights reserved.`
      : `&copy; 2006-${year} LendingClub Corporation. All rights reserved.`;

    return (
      <footer id='pf-footer' className='footer'>
        <div className='footer-inner page-inner'>
          <ExternalLinks links={externalLinks}/>
          { (footnotes && footnotes.length > 0) &&
            <div id='pf-footer-footnotes' className='footnotes'>
              { footnotes.map((__html, idx) =>
                <p key={`footnote-${idx}`} dangerouslySetInnerHTML={{__html}} />
              )}
            </div>
          }
          <p id='pf-footer-copyright' className='copyright'>
            <span dangerouslySetInnerHTML={{__html: footerText }} />
          </p>
        </div>
      </footer>
    );

};

LandingFooter.propTypes = {
  externalLinks: PropTypes.shape({
    termsOfUse: linkType,
    privacy: linkType,
    accessibility: linkType
  }),
  privacyPath: PropTypes.string,
  footnotes: PropTypes.array,
  copyRightYear: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ])
};

export default LandingFooter;

