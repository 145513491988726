const quotes = {
  dental: {
    text: 'I\'m a huge proponent of patient financing—thanks to LendingClub Patient Solutions we\'ve closed cases that otherwise wouldn\'t have proceeded.',
    author: 'S.T., Chief Operating Officer, Florida'
  },
  implants: {
    text: 'When it comes to patient financing, LendingClub Patient Solutions is my go-to partner. They\'ve got great payment alternatives for cases of all sizes, from single implants to comprehensive smile makeovers.',
    author: 'S.R., Dentist, New Jersey'
  },
  cosmetic: {
    text: 'When it comes to patient financing, LendingClub Patient Solutions is my go-to partner. They\'ve got great payment alternatives for cases of all sizes, from single implants to comprehensive smile makeovers.',
    author: 'S.R., Dentist, New Jersey'
  }
};

export default quotes;

require('/var/lib/jenkins/workspace/pf-landing-pages_Release_0/src/pages/trypatientsolutions-plus/trypatientsolutions-plus.scss');