import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Row from '@shared_modules/landing/components/grid/Row';
import Col from '@shared_modules/landing/components/grid/Col';

class TextInput extends Component {

  static defaultProps = {
    element: {
      name: 'name',
      colLabelClassName: 'label',
      colFieldClassName: 'field',
      colValidationClassName: 'messaging'
    },
    placeholder: '',
    inputMaxLength: 34,
    colLayout: {
      1: { sm: 12, lg: 3 },
      2: { sm: 12, lg: 3 },
      3: { sm: 12, lg: 3 },
      4: { sm: 12, lg: 3 }
    }
  }

  validZip = (zip) => {
    let isValid = false;

    if (zip && zip.length === 5 && !isNaN(zip)) {
      isValid = true;
    }

    return isValid;
  }

  existSpecialChars = (str) => {
    const regex = /[\\(){}:;|!@#$%^&*_=+~`,"\/]/gi;
    return regex.test(str);
  }

  handleBlur = (ev) => {
    const { onEventCallback, fieldMetadata } = this.props,
      { value, name } = ev.target,
      fieldValue = value.trim();

    let isValid = fieldValue && !this.existSpecialChars(fieldValue);

    if (name === 'practice-zip-code') {
      isValid = this.validZip(fieldValue);
    }

    fieldMetadata.value = fieldValue;
    fieldMetadata.isValid = isValid;
    fieldMetadata.status = isValid ? '' : 'invalid';

    if (onEventCallback && typeof onEventCallback === 'function') {
      onEventCallback(ev, fieldMetadata);
    }
  }

  handleFocus = (ev) => {
    const { fieldMetadata } = this.props;
    fieldMetadata.status = '';
  }

  render() {
    let autoCompleteFName = null;

    const { colLayout, element, inputMaxLength,
      placeholder, fieldMetadata, pageType } = this.props,
      prefix = 'pf-landing-';

    return (
      <Row className={prefix + fieldMetadata.name}>
        <Col
          className={element.colLabelClassName}
          width={colLayout[1]}
        >
          <label
            id={prefix + fieldMetadata.name + '-label'}
            className={pageType + '-label'}
            htmlFor={prefix + fieldMetadata.name}
          >{fieldMetadata.label}</label>
        </Col>
        <Col
          className={element.colFieldClassName}
          width={colLayout[2]}
        >
          <input
            key={fieldMetadata.value}
            id={prefix + fieldMetadata.name}
            className={fieldMetadata.status}
            defaultValue={fieldMetadata.value}
            name={fieldMetadata.name}
            type='text'
            placeholder={placeholder}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            maxLength={inputMaxLength}
            autoComplete={autoCompleteFName}
          />
        </Col>
      </Row>
    );
  }
}

TextInput.propTypes = {
  colLayout: PropTypes.object.isRequired,
  element: PropTypes.object,
  onEventCallback: PropTypes.func,
  inputMaxLength: PropTypes.number,
  fieldMetadata: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired
};

export default TextInput;

