import React from 'react';
import PropTypes from 'prop-types';

const LCTitle = ({ className, data, level }) => {

  // render empty component, no data
  if (!data || data.length === 0) {
    return null;
  }

  const Hdr = (level) ? `h${level}` : 'h1',
    dataIsArray = Array.isArray(data),
    baseClass = 'lc-title',
    wrapClass = (className)
      ? `${className} ${baseClass}` : baseClass;

  return (dataIsArray)
    ? (
      <Hdr className={wrapClass}>
        { dataIsArray && data.map((str, idx) => (
            <span
              key={'key-' + idx}
              className='text'
            >{str}</span>
          ))
        }
      </Hdr>
    )
    : (
      <div className={wrapClass}>
        <span className='text'>{data}</span>
      </div>
    );
};

LCTitle.propTypes = {
  className: PropTypes.string,
  level: PropTypes.number.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.array.isRequired
  ])
};

export default LCTitle;

