import React, { Component } from 'react';
import HeroSection from '@shared_modules/landing/components/HeroSection';
import PromoSection from '@shared_modules/landing/components/PromoSection';
import QuoteSection from '@shared_modules/landing/components/QuoteSection';
import LCTitle from '@shared_modules/landing/components/LCTitle';
import LandingHeader from '@shared_modules/landing/components/LandingHeader';
import LandingFooter from '@shared_modules/landing/components/LandingFooter';
import NavMenu from '@shared_modules/landing/components/NavMenu';
import Form from './Form';
import titles from '../content/title-data';
import { getFootnotesData } from '../content/footnote-data';
import getPromos from '../content/promotional-data';
import formMeta from '../content/form-metadata';
import disclosureData from '../content/disclosure-data';
import quotes from '../content/quote-data';


const pathname = window.location.pathname,
  titleText = {
    base: 'LendingClub Patient Solutions | ',
    dental: 'Empower your patients',
    implants: 'Finance dental implants',
    cosmetic: 'Increase cosmetic case acceptance'
  };
let lpName = 'dental',
  pageTitle = `${titleText.base}${titleText.dental}`;

if (pathname.indexOf('implants') !== -1) {
  lpName = 'implants';
  pageTitle = `${titleText.base}${titleText.implants}`;
}

if (pathname.indexOf('cosmeticdentistry') !== -1) {
  lpName = 'cosmetic';
  pageTitle = `${titleText.base}${titleText.cosmetic}`;
}

class LandingDental extends Component {

  constructor (props) {
    super(props);

    this.state = {
      showDarkBg: lpName !== 'implants',
      pageHasScrolled: false,
      baseImgClass: (lpName === 'dental')
        ? `hero-${lpName}`
        : `hero-dental-${lpName}`,
      lpName: lpName || props.lpName
    };
  }

  static defaultProps = {
    navItems: [
      { type: 'a', href: '/patientsolutions/patients', text: 'Patient Info' }
    ],
    navActions: [
      { type: 'a', href: '/patientsolutions/enroll', text: 'Enroll Now' }
    ],
    lpName: 'dental' // assumes trypatientsolutions route
  }

  componentDidMount () {
    window.document.title = pageTitle;
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const top  = window.pageYOffset || document.documentElement.scrollTop;
    this.setState({ pageHasScrolled: top > 0 });
  }

  render () {
    const { pageHasScrolled, baseImgClass, lpName, showDarkBg } = this.state,
      { navItems, navActions, displayLegalUpdates, rates, siteConfig } = this.props,
      { formDisclosures } = disclosureData,
      wrapClass = `pf-landing lp-${lpName} ${showDarkBg ? 'dark-background' : 'light-background'}`,
      wrapID = `pf-lp-${lpName}`,
      titleData = titles[lpName],
      promoData = getPromos(rates.min)[lpName],
      quoteData = quotes[lpName],
      footnotes = getFootnotesData(rates),
      footnoteData = footnotes[lpName] || null;

    const heroComponents = [
      <LCTitle key='item1' data={titleData} />,
      <Form
        key='item2'
        formMetadata={formMeta}
        disclosures={formDisclosures}
        pageType={baseImgClass}
      />
    ];

    return (
      <div id={wrapID} className={wrapClass}>
        <LandingHeader
          displayActions={pageHasScrolled}
        >
          <NavMenu
            navItems={navItems}
            navActions={navActions}
          />
        </LandingHeader>
        <main className='main'>
          <HeroSection
            className='dental-hero bg-dark'
            data={heroComponents}
            baseImgClass={baseImgClass}
          />
          <PromoSection
            className='dental-promo-top'
            data={promoData.topPromo}
          />
          <QuoteSection
            className='dental-quote'
            data={quoteData}
          />
          <PromoSection
            className='dental-promo-bottom'
            data={promoData.btmPromo}
          />
        </main>
        <LandingFooter
          externalLinks={siteConfig?.externalLinks}
          footnotes={footnoteData}
          displayLegalUpdates={displayLegalUpdates}
        />
    </div>
    );
  }
}

export default LandingDental;

require('/var/lib/jenkins/workspace/pf-landing-pages_Release_0/src/pages/trypatientsolutions-plus/trypatientsolutions-plus.scss');