// eslint-disable-next-line
import React from 'react';
import { render } from 'react-dom';
import LandingDental from './components/LandingDental';
import registry from 'dynamic-module-registry';

const context = registry.get('pageData');

render(
  <LandingDental {...context} />,
  document.getElementById('react-container')
);

require('/var/lib/jenkins/workspace/pf-landing-pages_Release_0/src/pages/trypatientsolutions-plus/trypatientsolutions-plus.scss');