import React from 'react';
import PropTypes from 'prop-types';

const QuoteSection = ({ baseClass, className, data }) => {

  const { text, author } = data,
    authorText = (author) ? `— ${author}` : '',
    wrapClass = (className) ? `${className} ${baseClass}` : baseClass;

  // render empty component, no data
  if (!text || text.length === 0) {
    return null;
  }

  return (
    <section className={wrapClass}>
      <blockquote className='quote-blockquote'>
        <div className='quote-text-contain'>
          <p className='quote-text'>
            <strong className='text'>{text}</strong>
          </p>
        </div>
        { authorText &&
          <cite
            className='quote-cite'
          >{authorText}</cite>
        }
      </blockquote>
    </section>
  );
};

QuoteSection.defaultProps = {
  baseClass: 'quote',
  data: { quote: { text: '', author: '' } }
};

QuoteSection.propTypes = {
  baseClass: PropTypes.string.isRequired,
  className: PropTypes.string,
  data: PropTypes.object.isRequired
};

export default QuoteSection;

