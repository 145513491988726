import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LandingHeader extends Component {

  constructor (props) {
    super(props);

    this.state = {};
  }

  static defaultProps = {
    baseClass: 'header'
  }

  render () {

    const
      { baseClass, className, children } = this.props,
      wrapClass = (className)
        ? `${className} ${baseClass}` : baseClass;

    return (
      <header className={wrapClass}>
        <div className='page-inner'>
          <a id='pf-logo-home-link' className='logo-container' href='/patientsolutions' aria-label='LendingClub Patient Solutions Logo'>
            <div
              role='img'
              className='logo' />
          </a>
          { children }
        </div>
      </header>
    );
  }
}

LandingHeader.propTypes = {
  baseClass: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string
};

export default LandingHeader;

