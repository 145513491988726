import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Row from '@shared_modules/landing/components/grid/Row';
import Col from '@shared_modules/landing/components/grid/Col';

class Email extends Component {

  static defaultProps = {
    element: {
      name: 'email',
      id: 'pf-landing-email',
      label: 'Email Address',
      placeHolder: '',
      colLabelClassName: 'label',
      colFieldClassName: 'field',
      colValidationClassName: 'messaging',
      maxLength: 50
    },
    colLayout: {
      1: { sm: 12, lg: 3 },
      2: { sm: 12, lg: 3 },
      3: { sm: 12, lg: 6 }
    }
  }

  isEmptyNotRequired = (value) => {
    const { isRequired } = this.props.fieldMetadata,
      hasNoValue = (value === '' || value === undefined);
    return (hasNoValue && !isRequired) ? true : false;
  }

  isFormatValid = (value) => {
    if (value.indexOf('xn--') !== -1) {
      return false;
    }
    const emailRegex = /^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-\+]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
    return emailRegex.test(value);
  }

  handleBlur = (ev) => {
    const { value } = ev.target,
      { fieldMetadata, onEventCallback } = this.props;

    fieldMetadata.value = value;
    fieldMetadata.isValid = this.isFormatValid(value);
    fieldMetadata.status = fieldMetadata.isValid ? '' : 'invalid';

    if (onEventCallback && typeof onEventCallback === 'function') {
      onEventCallback(ev, fieldMetadata);
    }
  }

  handleFocus = (ev) => {
    const { fieldMetadata } = this.props;
    fieldMetadata.status = '';
  }

  render() {
    const { colLayout, element, fieldMetadata, pageType } = this.props,
      { maxLength } = element;

    return (
      <Row className={element.id}>
        <Col
          className={element.colLabelClassName}
          width={colLayout[1]}
        >
          <label
            id={element.id + '-label'}
            className={pageType + '-label'}
            htmlFor={element.id}
          >{fieldMetadata.label}</label>
        </Col>
        <Col
          className={element.colFieldClassName}
          width={colLayout[2]}>
          <input
            id={element.id}
            name={fieldMetadata.name}
            className={fieldMetadata.status}
            type='email'
            placeholder={element.placeHolder}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            maxLength={maxLength}
            defaultValue={fieldMetadata.value}
            autoComplete={fieldMetadata.autocomplete || null}
          />
        </Col>
      </Row>
    );
  }
}

Email.propTypes = {
  colLayout: PropTypes.object.isRequired,
  element: PropTypes.object.isRequired,
  onEventCallback: PropTypes.func,
  fieldMetadata: PropTypes.object.isRequired,
  verifyEmailPath: PropTypes.string,
  pageType: PropTypes.string.isRequired
};

export default Email;

