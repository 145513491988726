import React, { Component } from 'react';
import PropTypes from 'prop-types';
import http from 'lc-http';
import https from 'https';
import SelectInput from '@shared_modules/landing/components/form/SelectInput';
import TextInput from '@shared_modules/landing/components/form/TextInput';
import Email from '@shared_modules/landing/components/form/Email';
import Phone from '@shared_modules/landing/components/form/Phone';
import classnames from 'classnames';

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasSubmittedBE: false,
      hasInvalidFields: false,
      hasErroredBE: false,
      hasCompletedBE: false,
      validForm: false,
      formMetadata: props.formMetadata
    };
  }

  static defaultProps = {
    baseClass: 'hero-form-container',
    layout: 'layout-vertical',
    formMetadata: { title: '', fields: {} },
    disclosures: {},
    pageType: 'hero-dental'
  };

  renderDisclosures() {
    const { disclosures, pageType } = this.props,
      paraList = Object.values(disclosures),
      disclosureClassname = `form-disclosures ${pageType}-disclosures`;

    return (
      <div className={disclosureClassname}>
        {paraList.map((para, idx) => (
          <p key={`p-${idx}`}>{para}</p>
        ))}
      </div>
    );
  }

  updateFormStatus = () => {
    const { formMetadata } = this.state,
      { fields } = formMetadata;

    for (const f in fields) {
      if (fields.hasOwnProperty(f)) {
        const field = fields[f];
        if (!field.isValid) {
          field.status = 'invalid';
        }
      }
    }

    this.setState({ formMetadata });
  };

  validPhone = (value) => {
    return value.length === 10;
  };

  validEmail = (value) => {
    if (!value || value.indexOf('xn--') !== -1) {
      return false;
    }

    const emailRegex = /^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-\+]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
    const isValid = emailRegex.test(value);
    return isValid;
  };

  validZip = (zip) => {
    let isValid = false;

    if (zip && zip.length === 5 && !isNaN(zip)) {
      isValid = true;
    }

    return isValid;
  };

  validateForm = () => {
    const { formMetadata } = this.state,
      { fields } = formMetadata;
    let validForm = false;

    const name = fields['provider-name'].value,
      practiceZip = fields['practice-zip-code'].value,
      practiceName = fields['practice-name'].value,
      email = fields.email.value,
      phone = fields['phone-number'].value,
      howHear = fields['how-hear'].value;

    if (
      name &&
      this.validZip(practiceZip) &&
      practiceName &&
      howHear &&
      this.validEmail(email) &&
      this.validPhone(phone)
    ) {
      validForm = true;
    }

    return validForm;
  };

  buildApiPayload = (data) => {
    return {
      practiceType: 'dental',
      firstName: data.fields['provider-name'].value,
      practiceName: data.fields['practice-name'].value,
      email: data.fields.email.value,
      telePhone: data.fields['phone-number'].value,
      zipCode: data.fields['practice-zip-code'].value,
      hearOf: data.fields['how-hear'].value,
      refUrl: window.location.href
    };
  };

  handleSubmit = (ev) => {
    ev.preventDefault();

    const BASE_PATH = '/trypatientsolutions',
      SUBMIT = '/submit-form',
      DOWNLOAD = '/download',
      agent = new https.Agent({
        rejectUnauthorized: false
      });

    const submitURL = BASE_PATH + SUBMIT,
      downloadURL = BASE_PATH + DOWNLOAD,
      { formMetadata } = this.state,
      payload = this.buildApiPayload(formMetadata);

    if (!this.validateForm()) {
      this.updateFormStatus();
      return;
    }

    http
      .post(submitURL, payload, { httpsAgent: agent })
      .then((response) => {
        this.setState({ hasSubmittedBE: true });
        window.open(downloadURL, '_blank');
      })
      .catch((error) => {
        console.log('****** ERROR - submit');
      });
  };

  handleEventCallback = (ev, data) => {
    const { formMetadata } = this.state,
      { name } = ev.target;

    formMetadata.fields[name].value = data.value;
    formMetadata.fields[name].isValid = data.isValid;
    formMetadata.fields[name].status = data.isValid ? '' : 'invalid';
    this.setState({ formMetadata });
  };

  renderForm() {
    const { className, baseClass, layout, disclosures, pageType } = this.props,
      { formMetadata } = this.state,
      { fields } = formMetadata,
      wrapClass = classnames({
        [className]: className,
        [baseClass]: true,
        [layout]: true
      }),
      numDisclosures = Object.keys(disclosures).length || 0;

    const subtitleClassname = `lc-subtitle ${pageType}-subtitle`,
      formClassname = `form ${pageType}-form`;

    return (
      <div className={wrapClass}>
        <h2 className={subtitleClassname}>
          <span>Download a </span>
          <strong>free</strong>
          <span> overview of payment options.</span>
        </h2>
        <form
          className={formClassname}
          onSubmit={this.handleSubmit}
          acceptCharset="utf-8"
        >
          <TextInput
            nameGroupLabel="practice-name"
            onEventCallback={this.handleEventCallback}
            fieldMetadata={fields['practice-name']}
            pageType={pageType}
          />
          <TextInput
            nameGroupLabel="practice-zip-code"
            onEventCallback={this.handleEventCallback}
            fieldMetadata={fields['practice-zip-code']}
            pageType={pageType}
            inputMaxLength={5}
          />
          <TextInput
            nameGroupLabel="provider-name"
            onEventCallback={this.handleEventCallback}
            fieldMetadata={fields['provider-name']}
            pageType={pageType}
          />
          <Email
            onEventCallback={this.handleEventCallback}
            fieldMetadata={fields.email}
            pageType={pageType}
          />
          <SelectInput
            nameGroupLabel="how-hear"
            onEventCallback={this.handleEventCallback}
            fieldMetadata={fields['how-hear']}
            pageType={pageType}
          />
          <Phone
            onEventCallback={this.handleEventCallback}
            fieldMetadata={fields['phone-number']}
            pageType={pageType}
          />
          <div className="form-actions">
            <button id="pf-dental-landing-btn-submit">
              Download More Info
            </button>
          </div>
          {numDisclosures > 0 && this.renderDisclosures()}
        </form>
      </div>
    );
  }

  renderComplete() {
    const { className, baseClass, pageType } = this.props,
      wrapClass = className ? `${className} ${baseClass}` : baseClass,
      thankYouClassname = `${pageType}-thankyou-msg hero-container-thankyou-msg`,
      subtitleClassname = `lc-subtitle bold ${pageType}-subtitle`;

    return (
      <div className={wrapClass}>
        <div className={thankYouClassname}>
          <h2 className={subtitleClassname}>
            Thanks for your interest in our patient financing program!
          </h2>
          <p>
            We&#39;re excited to help you offer your patients a more affordable
            way to pay for treatment. A member of our team will be reaching out
            shortly to help answer any questions and discuss your practice
            goals.
          </p>
        </div>
      </div>
    );
  }

  renderEmpty() {
    const { className, baseClass } = this.props,
      wrapClass = className ? `${className} ${baseClass}` : baseClass;

    return <div className={wrapClass}></div>;
  }

  render() {
    const { hasSubmittedBE, formMetadata } = this.state;

    // empty formMetadata
    if (
      !formMetadata ||
      !formMetadata.fields ||
      Object.keys(formMetadata.fields) === 0
    ) {
      this.renderEmpty();
    }

    return hasSubmittedBE ? this.renderComplete() : this.renderForm();
  }
}

Form.propTypes = {
  className: PropTypes.string,
  layout: PropTypes.string,
  formMetadata: PropTypes.object.isRequired,
  disclosures: PropTypes.object.isRequired,
  pageType: PropTypes.string.isRequired
};

export default Form;

require('/var/lib/jenkins/workspace/pf-landing-pages_Release_0/src/pages/trypatientsolutions-plus/trypatientsolutions-plus.scss');